/* ----- Font Styles: ----- */
@font-face {
  font-family: "CalibreWeb";
  src: url("fonts/CalibreWeb-Regular.eot");
  src: url("fonts/CalibreWeb-Regular.eot?#iefix") format("embedded-opentype"),
    url("fonts/CalibreWeb-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CalibreWeb";
  src: url("fonts/CalibreWeb-Medium.eot");
  src: url("fonts/CalibreWeb-Medium.eot?#iefix") format("embedded-opentype"),
    url("fonts/CalibreWeb-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "CalibreWeb";
  src: url("fonts/CalibreWeb-Semibold.eot");
  src: url("fonts/CalibreWeb-Semibold.eot?#iefix") format("embedded-opentype"),
    url("fonts/CalibreWeb-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}
