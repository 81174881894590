:root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.75);
  --rsbs-bg: hsl(0, 0%, 100%);
  --rsbs-handle-bg: hsla(0, 0%, 100%, 0.18);
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
}

#layout {
  background-color: #040507;
  padding-top: 62px;

  > * {
    background-color: #040507;
  }

  @media screen and (min-width: 640px) {
    border-left: 1px solid #3b3b3e;
    border-right: 1px solid #3b3b3e;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: white !important;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: none;
}
